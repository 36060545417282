<template>
  <div class="container">
    <h1 style="margin-top: 0px;">Stato Server Contabo</h1>
    <p v-if="user">Benvenuto, {{ user.displayName }} </p>
    <div v-if="user" >
      <img class="user-img" :src="user.picture">
      <div style="display: inline-block;vertical-align: middle;height: 3.5rem;"> {{ user.email }} <a @click.prevent="logout" href="#">Logout</a></div>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th @click='sort("alias")' style="cursor:pointer">nome {{ sortBy == 'alias' ? '*' : '' }}</th>
          <th @click='sort("name")' style="cursor:pointer">vps {{ sortBy == 'name' ? '*' : '' }}</th>
          <th @click='sort("ip")' style="cursor:pointer">ip {{ sortBy == 'ip' ? '*' : '' }}</th>
          <th @click='sort("ramMb")' style="cursor:pointer">ram Gb {{ sortBy == 'ramMb' ? '*' : '' }}</th>
          <th @click='sort("cpuCores")' style="cursor:pointer">cpu {{ sortBy == 'cpuCores' ? '*' : '' }}</th>
          <th @click='sort("diskMb")' style="cursor:pointer">disco Gb {{ sortBy == 'diskMb' ? '*' : '' }}</th>
          <th @click='sort("status")' style="cursor:pointer">stato {{ sortBy == 'status' ? '*' : '' }}</th>
          <th>azioni</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="server in sortedList" :key="server.name">
          <td class="text-lowercase">{{server.alias}}</td>
          <td>{{server.name}}</td>
          <td>{{server.ip}}</td>
          <td>{{server.ramMb / 1024}}</td>
          <td>{{server.cpuCores}}</td>
          <td>{{server.diskMb / 1024}}</td>
          <td>{{server.status}}</td>
          <td><button @click='restart(server)' type="button" class="btn btn-sm btn-danger">riavvia</button></td>
        </tr>
      </tbody>
  </table>
  </div>
</template>

<script>
export default {
  name: "DashboardComponent",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      servers: [],
      sortOrder: 1,
      sortBy: "alias",
      luna_servers: new Set([
        "5.189.178.195",
        "75.119.139.225",
        "79.143.189.221",
        "194.163.159.233",
        "173.212.211.67",
        "95.111.241.210",
        "173.212.215.187",
        "173.249.7.250",
        "144.91.76.138",
        "38.242.140.198",
        "207.180.254.191",
        "192.145.37.96"
      ]),
      dict: new Map([
        ["144.91.111.151", "mimas"],
        ["5.189.138.24", "naiade"],
        ["167.86.112.23", "thrymr"],
        ["5.189.135.155", "ymir"],
        ["173.249.24.124", "Muletto 10"],
        ["213.136.69.136", "Muletto 11"],
        ["173.212.248.18", "Muletto 12"],
        ["213.136.76.61", "Muletto 5"],
        ["173.212.221.9", "Muletto 6"],
        ["213.136.84.138", "Muletto 8"],
        ["213.136.94.80", "Muletto 9"],
        ["167.86.108.195", "Tarvos"],
        ["5.189.178.195", "Regin"],
        ["75.119.139.225", "Cillene"],
        ["79.143.189.221", "Magnum"],
        ["194.163.159.233", "Eucelade"],
        ["173.212.211.67", "Pandora"],
        ["95.111.241.210", "Pasitea"],
        ["173.212.215.187", "Egir"],
        ["173.249.7.250", "Giano"],
        ["144.91.76.138", "Abra"],
        ["38.242.140.198", "Absol"],
        ["173.212.202.3", "Titano"],
        ["5.189.166.132", "Encelado"],
        ["5.189.174.199", "Teti"],
        ["173.212.226.2", "Atlante"],
        ["213.136.80.101", "Lunatestdb"],
        ["167.86.86.123", "Albiorix"],
        ["207.180.226.32", "Bebhionn"],
        ["5.189.189.173", "Bestla"],
        ["5.189.180.174", "Calipso"],
        ["62.171.128.5", "Dafni"],
        ["173.249.56.250", "Elena"],
        ["5.189.170.119", "Epimeteo"],
        ["173.212.202.233", "errapio"],
        ["62.171.160.104", "Farbauti"],
        ["178.238.227.194", "Febe"],
        ["173.212.220.140", "gaia"],
        ["178.238.225.4", "Giapeto"],
        ["213.136.70.89", "Hati"],
        ["173.249.40.105", "Iperione"],
        ["5.189.179.21", "ipp"],
        ["173.249.51.106", "jarnsaxa"],
        ["93.104.208.138", "Kari"],
        ["5.189.177.116", "Loge"],
        ["213.136.85.212", "Lunadocker"],
        ["173.212.254.174", "Metone"],
        ["173.212.211.40", "Mimante"],
        ["79.143.181.239", "pan"],
        ["213.136.93.15", "partner-mach-1"],
        ["213.136.91.119", "Polluce"],
        ["173.212.201.218", "Rea"],
        ["173.212.203.112", "Rischiocyber"],
        ["5.189.162.89", "Skoll"],
        ["167.86.112.28", "vmi359691"],
        ["213.136.91.103", "yuri"],
        ["213.136.69.81", "Drexmood"],
        ["5.189.181.253", "Dione"],
        ["207.180.254.191", "Bergelmir"]
      ])
    };
  },
  computed: {
    sortedList() {
      return this.servers
        .map(i => ({...i}))
        .sort((a,b) => {
          if (this.sortBy == "name" || this.sortBy == "alias" ||
            this.sortBy == "ip" || this.sortBy == "status"
          ) {
            if (a[this.sortBy] == undefined && b[this.sortBy] == undefined) {
              return 0;
            }
            if (a[this.sortBy] == undefined) {
              return 1 * this.sortOrder;
            }
            if (b[this.sortBy] == undefined) {
              return -1 * this.sortOrder;
            }
            return a[this.sortBy].localeCompare(b[this.sortBy]) * this.sortOrder;
          }
          if (a[this.sortBy] >= b[this.sortBy]) {
            return this.sortOrder
          }
          return -this.sortOrder
      })
    }
  },
  methods: {
    async getData() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + "/contabo/list", {
          credentials: "include",
        });

        if (response.ok) {
          const dati = await response.json();
          console.log(dati);
          const servers = dati.data.map((obj) => {
            obj.ip = obj.ipConfig.v4.ip;
            if (this.dict.has(obj.ip)) {
              obj.alias = this.dict.get(obj.ip);
            } else {
              obj.alias = obj.name;
            }
            return obj;
          });
          if (this.user.domain != "presstoday.com") {
            this.servers = servers.filter((obj) => !this.luna_servers.has(obj.ip))
          } else {
            this.servers = servers;
          }
        } else {
          console.error("Error:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async restart(server) {
      if (!confirm("Riavviare " + server.alias + " (" + server.ip + ")?")) {
        return;
      }
      try {
        const response = await fetch(
          process.env.VUE_APP_API_URL + "/contabo/restart?instanceId=" + server.instanceId,
          { credentials: "include" }
        );
        if (response.ok) {
          alert("istanza riavviata: " + server.alias + " (" + server.ip + ")");
          this.getData();
        } else {
          console.log("Restart failed.");
          alert("restart FAILED");
        }
      } catch (error) {
        console.log("Restart failed:", error);
        alert("restart FAILED: " + error);
      }
    },
    sort: function(sortBy){
      if(this.sortBy === sortBy) {
        this.sortOrder = -this.sortOrder;
      } else {
        this.sortBy = sortBy
        this.sortOrder = 1;
      }
    },
    async logout() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + "/logout", {
          credentials: "include",
        });

        if (response.ok) {
          localStorage.removeItem("user");
          this.$router.push({ name: "home" });
        } else {
          console.error("Logout failed:", response.status);
        }
      } catch (error) {
        console.error("Error during logout:", error);
      }
    },
  },
  mounted() {
      this.getData()
    }
};
</script>

<style>
.user-img {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.5rem;
  border-radius: 100%;
  vertical-align: unset;
}
</style>