import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import Dashboard from './views/Dashboard.vue';
import Logout from './views/Logout.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = localStorage.getItem('user');

    if (user) {
      next();
    } else {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + '/user', {
          credentials: 'include',
        });

        if (response.status === 401) {
          next({ name: 'login' });
          return;
        }

        const user = await response.json();
        localStorage.setItem('user', JSON.stringify(user));
        next();
      } catch (error) {
        console.error('Error fetching user:', error);
        next({ name: 'login' });
      }
    }
  } else {
    next();
  }
});

export default router;
